import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'src/utils/common'
import { Link } from 'react-router-dom'
import { includes, uniq } from 'lodash'
import classNames from 'classnames'
import { matchPath } from 'react-router'
import { bindActionCreators } from 'redux'
import { ReduxState } from '../../store/reducers'
import { withStyles } from '@material-ui/core/styles'
import { Drawer, Icon, List, ListItem, ListSubheader, Avatar, Typography, Tooltip } from '@material-ui/core'

import BetaBadge from './BetaBadge'
import { QueryS3Url } from './QueryS3Url'
import { isLPUser } from 'src/utils/common'
import { getInitials } from 'src/utils/common'
import { getTranslation } from '../../translation'
import { getTubeData } from 'src/gql/queries/getTube'
import { SIDEBAR_WIDTH } from 'src/constants/common'
import { listTracksData } from 'src/gql/queries/listTracks'
import { listChannelsData } from 'src/gql/queries/listChannels'
import { getUserFollowData } from 'src/gql/queries/getUserFollowData'
import {
  isTasksFeatureEnabled,
  isNotificationsFeatureEnabled,
  isChatFeatureEnabled,
  isNewChannelLayoutFeatureEnabled,
} from 'src/utils/featureFlagChecks'
import { isCurrentPageAdminPage, isCurrentPageNotificationPage } from 'src/utils/common'
import {
  toggleSidebar,
  updateScrollPosition,
  toggleSubscriptionsMenu,
  toggleTracksExpandMenu,
} from '../../store/actions'
import ChannelList from './sidebar/ChannelList'
import PersonalChannelList from './sidebar/PersonalChannelList'

const styles = theme => ({
  drawer: {
    '& > div': {
      borderRight: 0,
    },
    '@media(max-width: 1024px)': {
      display: 'none',
    },
  },
  sidebar: {
    overflowY: 'overlay',
    overflowX: 'hidden',
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 8,
        position: 'fixed',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 0,
    },
    height: '100vh',
    backgroundColor: '#303030',
  },
  navigation: {
    height: 65,
    padding: '15px 20px',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  backIcon: {
    color: 'white',
    marginTop: 5,
    cursor: 'pointer',
    marginLeft: 172,
    marginBottom: 9,
  },
  header: {
    position: 'relative',
  },
  hamburgerWrapper: {
    paddingLeft: 20,
    paddingRight: 5,
  },
  hamburger: {
    cursor: 'pointer',
  },
  text: {
    marginLeft: 42,
    lineHeight: '14.5px',
    fontWeight: 400,
    color: 'white',
  },
  name: {
    fontSize: 16,
  },
  email: {
    'white-space': 'nowrap',
    marginTop: 8,
    fontSize: 13,
    opacity: 0.5,
  },
  photo: {
    backgroundColor: '#303030',
    padding: 8,
    height: 72,
    width: 72,
    overflow: 'hidden',
    position: 'absolute',
    bottom: -30,
    left: 137,
    borderRadius: '50%',
    transform: 'translateX(-50%)',
    '& > img': {
      borderRadius: '50%',
    },
  },
  heading: {
    'text-transform': 'uppercase',
    color: 'rgb(96, 96, 96)',
    fontSize: 14,
    lineHeight: '48px',
    fontWeight: 500,
  },
  headingPadding: {
    // paddingTop: 20,
  },
  item: {
    color: 'rgb(3,3,3) !important',
    textDecoration: 'none !important',
  },
  content: {},
  userHeader: {
    color: 'white !important',
  },
  userColumn: {
    color: 'white !important',
    paddingLeft: '20px !important',
  },
  mobileHidden: {
    '@media(max-width: 1024px)': {
      display: 'none',
    },
  },
  showMoreButtonWrapper: {
    cursor: 'pointer',
    color: 'rgb(3,3,3)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    marginTop: 5,
    marginBottom: 5,
  },
  menuText: {
    fontWeight: 400,
    fontSize: 14,
  },
  channelPreloader: {
    width: 22,
    height: 23,
    marginRight: 10,
    maxWidth: '100%',
    backgroundColor: '#cccccc',
  },
  groupSeparator: {
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    padding: '8px 0',
  },
  channelSubscriptionWrapper: {
    width: 280,
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
    '@media(max-width: 1305px)': {
      width: 245,
    },
  },
  selectedChannelSubscriptionWrapper: {
    width: 280,
    backgroundColor: '#E5E5E5',
    '&:hover': {
      backgroundColor: '#D9D9D9',
    },
    '@media(max-width: 1305px)': {
      width: 245,
    },
  },
  logo: {
    paddingLeft: 14,
    paddingTop: 5,
    maxHeight: 51,
  },
  menuClass: {
    color: 'rgba(96,96,96)',
    marginRight: 10,
  },
  topNavImg: {
    color: 'rgba(96,96,96)',
    marginRight: 10,
    width: 23,
    height: 23,
  },
  subscriptionItem: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    textDecoration: 'none !important',
  },
  channelImg: {
    marginRight: 10,
    width: 22,
    height: 23,
    borderRadius: '100%',
    border: '1px solid #eee',
    display: 'flex',
    position: 'relative',
    fontSize: '1rem',
    justifyContent: 'center',
  },
  channelText: {
    marginTop: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  topNavItem: {
    width: '100%',
    display: 'flex',
    padding: '12px 24px',
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: '#F2F2F2',
      color: 'rgb(3,3,3)',
    },
  },
  selectedTopNavItem: {
    width: '100%',
    display: 'flex',
    padding: '12px 24px',
    backgroundColor: '#E5E5E5',
    fontWeight: 500,
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: '#D9D9D9',
      color: 'rgb(3,3,3)',
    },
  },
  topOptions: {
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    paddingBottom: 8,
  },
  topNavText: {
    color: 'rgb(3,3,3)',
    marginTop: 3,
    width: '200px',
  },
  menuContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    height: 56,
  },
})

const TRACKS_TO_SHOW_BEFORE_EXPAND = 5

const Item = ({ classes, item, translate, isBeta }) => {
  const Comp = (
    <Link id={item.id} name={item.name} to={item.linkTo} className={classes.item}>
      {item.name}
    </Link>
  )

  return (
    <ListItem
      button={true}
      className={item.linkTo === '/admin/reports' || item.linkTo === '/admin/users' ? classes.mobileHidden : ''}
    >
      {isBeta ? <BetaBadge badgeContent={translate('beta')}>{Comp}</BetaBadge> : Comp}
    </ListItem>
  )
}

const MenuItemWithIcon = (text, iconName, routeTo, id, isPageSelected, classes, tubeColor) => (
  <Link id={id} name={text} to={routeTo} className={!isPageSelected ? classes.topNavItem : classes.selectedTopNavItem}>
    <Icon style={isPageSelected ? { color: tubeColor } : {}} className={classes.topNavImg}>
      {iconName}
    </Icon>
    <span className={classes.topNavText}>{text}</span>
  </Link>
)

const Content = ({
  tubeId,
  classes,
  listTags,
  isLPUser,
  orgId,
  listUsers,
  translate,
  tubeColor,
  listVideos,
  listTracks,
  followBeta,
  isPersistent,
  getTubeQuery,
  currentPath,
  originalOrgId,
  listAzureAd,
  listChannels,
  listBranding,
  onPageChange,
  onCloseSidebar,
  listTracksQuery,
  followingFeature,
  listChannelsQuery,
  updateOrganization,
  listClassification,
  setTracksExpandMenu,
  videoProcessingBeta,
  isTracksMenuExpanded,
  isTasksFeatureEnabled,
  isChatFeatureEnabled,
  isNewChannelLayoutEnabled,
  isNotificationsFeatureEnabled,
  setSubscriptionsMenu,
  videoProcessingEnabled,
  getUserFollowDataQuery,
  isSubscriptionsMenuOpen,
  isRoleAdmin,
  isMasterAdmin,
  organizationName,
  tubeIcon,
}) => {
  const followedChannels =
    getUserFollowDataQuery && getUserFollowDataQuery.userFollowData && getUserFollowDataQuery.userFollowData.channels
      ? getUserFollowDataQuery.userFollowData.channels
      : []

  const availableFollowedChannels =
    followingFeature && followingFeature.configuration === 'tube'
      ? followedChannels
      : followedChannels.filter(channelId => includes(followingFeature.activatedIn, channelId))

  const channels = listChannelsQuery.channels
    ? listChannelsQuery.channels.filter(channel => includes(availableFollowedChannels, channel.id))
    : []

  const channelsToShow = channels && !isSubscriptionsMenuOpen ? channels.slice(0, 7) : channels
  const showExpandMenu = channels && channels.length > 7
  const expandedCount = channels ? channels.length - 7 : 0
  const currentChannelParams = matchPath(currentPath, {
    strict: false,
    exact: false,
    path: '/channel/:id/home',
  })

  const channelIds =
    listTracksQuery && listTracksQuery.tracks ? uniq(listTracksQuery.tracks.map(track => track.channelId)) : []
  const channelsTracks = listChannelsQuery.channels
    ? listChannelsQuery.channels.filter(channel => includes(channelIds, channel.id))
    : []

  const followedTracks =
    getUserFollowDataQuery &&
    listTracksQuery &&
    listTracksQuery.tracks &&
    getUserFollowDataQuery.userFollowData &&
    getUserFollowDataQuery.userFollowData.tracks
      ? listTracksQuery.tracks
          .filter(track => includes(getUserFollowDataQuery.userFollowData.tracks || [], track.id))
          .map(track => ({
            ...track,
            shouldShow: isLPUser,
            channelName: (
              channelsTracks.find(channel => channel.id === track.channelId) || {
                name: '',
              }
            ).name,
          }))
      : []

  const sideBarItems = [
    {
      name: translate('support'),
      icon: 'chat',
      linkTo: '/support/chat',
      id: 'support-chat-link',
      shouldShow: isChatFeatureEnabled,
      isSelected: currentPath === '/support/chat',
      isPage: true,
    },
    {
      name: translate('tasks'),
      icon: 'check_box',
      linkTo: '/tasks',
      id: 'your-tasks-link',
      shouldShow: isTasksFeatureEnabled,
      isSelected: currentPath === '/tasks',
      isPage: true,
    },
    {
      name: translate('history'),
      icon: 'history',
      linkTo: '/history',
      id: 'history-link',
      shouldShow: true,
      isSelected: currentPath === '/history',
      isPage: true,
    },

    ...(followedTracks || []),
  ]

  const logo =
    getTubeQuery.loading || getTubeQuery.error
      ? ''
      : getTubeQuery.tube.logo
      ? getTubeQuery.tube.logo
      : '/assets/images/logo.png'

  const sideBarItemsToShow = !isTracksMenuExpanded ? sideBarItems.slice(0, TRACKS_TO_SHOW_BEFORE_EXPAND) : sideBarItems

  const showTracksExpandMenu = sideBarItems.length > TRACKS_TO_SHOW_BEFORE_EXPAND
  const expandedTracksCount = sideBarItems.length ? sideBarItems.length - TRACKS_TO_SHOW_BEFORE_EXPAND : 0

  return (
    // @ts-ignore
    <List onClick={onPageChange} component="div" style={{ backgroundColor: 'white' }} className={classes.content}>
      {!isPersistent && (
        <div className={classNames('flex items-center', classes.menuContainer)}>
          <div onClick={onCloseSidebar} className={classes.hamburgerWrapper}>
            <Icon className={classes.hamburger}>menur</Icon>
          </div>
          <Link id="home-link" to="/home">
            {Boolean(logo) && <img className={classes.logo} src={logo} alt="logo" />}
          </Link>
        </div>
      )}
      <div className={classes.topOptions}>
        {MenuItemWithIcon(translate('home'), 'home', '/home', 'home-link', currentPath === '/home', classes, tubeColor)}
      </div>

      {(isLPUser || isTasksFeatureEnabled) && !originalOrgId && (
        <div className={classes.groupSeparator}>
          {sideBarItemsToShow
            .filter(item => item.shouldShow)
            .map(item => (
              <span key={item.id}>
                {item.isPage ? (
                  <span key={item.id}>
                    {MenuItemWithIcon(item.name, item.icon, item.linkTo, item.id, item.isSelected, classes, tubeColor)}
                  </span>
                ) : (
                  <Link
                    key={item.id}
                    name={item.name}
                    id="sharings-link"
                    to={`/channel/${item.channelId}/playlists`}
                    className={classes.topNavItem}
                  >
                    <Icon className={classes.topNavImg}>playlist_play</Icon>

                    <Tooltip placement="top" title={item.name}>
                      <Typography noWrap={true} className={classes.topNavText}>
                        {item.name}
                      </Typography>
                    </Tooltip>
                  </Link>
                )}
              </span>
            ))}

          {!!showTracksExpandMenu && isLPUser && (
            <div className={classes.showMoreButtonWrapper} onClick={() => setTracksExpandMenu(!isTracksMenuExpanded)}>
              {isTracksMenuExpanded ? (
                <>
                  <Icon className={classes.menuClass}>keyboard_arrow_up</Icon>
                  <span className={classes.menuText}>{translate('showLess')}</span>
                </>
              ) : (
                <>
                  <Icon className={classes.menuClass}>keyboard_arrow_down</Icon>
                  <span className={classes.menuText}>Show {expandedTracksCount} more</span>
                </>
              )}
            </div>
          )}
        </div>
      )}

      {!!channelsToShow.length && (
        <div className={classes.groupSeparator}>
          <ListSubheader disableSticky={true} className={classes.heading} component="div">
            {translate('subscriptions')}
          </ListSubheader>
          {channelsToShow.map(channel => {
            return (
              <span key={channel.id}>
                <QueryS3Url source={channel.icon}>
                  {({ loading, error, url }: any) => {
                    return (
                      <ListItem
                        className={classNames(
                          !!currentChannelParams &&
                            currentChannelParams.params &&
                            currentChannelParams.params.id === channel.id
                            ? classes.selectedChannelSubscriptionWrapper
                            : classes.channelSubscriptionWrapper
                        )}
                        key={channel.id}
                        button={true}
                      >
                        <Link
                          to={`/channel/${channel.id}/home`}
                          className={classNames(classes.subscriptionItem, classes.item)}
                          id={channel.name}
                          name={channel.name}
                        >
                          {loading ? (
                            <Avatar className={classes.channelPreloader} />
                          ) : (
                            <>
                              {channel.name && url ? (
                                <img className={classes.channelImg} src={url} alt="logo" />
                              ) : tubeIcon ? (
                                <img className={classes.channelImg} src={tubeIcon} alt="logo" />
                              ) : (
                                <Avatar
                                  style={{
                                    backgroundColor: tubeColor as any,
                                    color: 'white',
                                  }}
                                  className={classes.channelImg}
                                  src={url}
                                >
                                  {getInitials(channel.name)}
                                </Avatar>
                              )}
                            </>
                          )}
                          <span className={classes.channelText}> {channel.name}</span>
                        </Link>
                      </ListItem>
                    )
                  }}
                </QueryS3Url>
              </span>
            )
          })}

          {showExpandMenu && (
            <div
              className={classes.showMoreButtonWrapper}
              onClick={() => setSubscriptionsMenu(!isSubscriptionsMenuOpen)}
            >
              {isSubscriptionsMenuOpen ? (
                <>
                  <Icon className={classes.menuClass}>keyboard_arrow_up</Icon>
                  <span className={classes.menuText}>{translate('showLess')}</span>
                </>
              ) : (
                <>
                  <Icon className={classes.menuClass}>keyboard_arrow_down</Icon>
                  <span className={classes.menuText}>Show {expandedCount} more</span>
                </>
              )}
            </div>
          )}
        </div>
      )}
      {isNewChannelLayoutEnabled && <PersonalChannelList />}
      {isNewChannelLayoutEnabled && (
        <>
          <ChannelList
            listTitle={`${translate('company-channels')}`}
            filterFunction={channel => !channel.shared && !channel.isPersonalChannel}
          />
          <ChannelList listTitle={`${translate('external-channels')}`} filterFunction={channel => channel.shared} />
        </>
      )}

      {isRoleAdmin && (listVideos || listUsers || listChannels || listTracks || listTags) && !originalOrgId && (
        <div className={classes.groupSeparator}>
          <ListSubheader
            disableSticky={true}
            className={classNames(classes.heading, classes.headingPadding)}
            component="div"
          >
            {translate('content')}
          </ListSubheader>
          {[
            {
              id: 'admin-videos-link',
              name: translate('videos'),
              linkTo: '/admin/videos',
              visible: isRoleAdmin && listVideos,
              icon: 'ondemand_video',
              isSelected: currentPath === '/admin/videos',
            },
            {
              id: 'admin-users-link',
              name: translate('users'),
              linkTo: '/admin/users',
              visible: listUsers,
              icon: 'person_outline',
              isSelected: currentPath === '/admin/users',
            },
            {
              id: 'admin-channels-link',
              name: translate('channels'),
              linkTo: '/admin/channels',
              visible: listChannels,
              icon: 'apps',
              isSelected: currentPath === '/admin/channels',
            },
            {
              id: 'admin-tracks-link',
              name: translate('tracks'),
              linkTo: '/admin/playlists',
              visible: listTracks,
              icon: 'playlist_play',
              isSelected: currentPath === '/admin/playlists',
            },
            {
              id: 'tasks-link',
              name: translate('tasks'),
              linkTo: '/admin/tasks',
              visible: listTracks,
              icon: 'check_box',
              isSelected: currentPath === '/admin/tasks',
            },
            ...(isLPUser && (orgId === 'launchpeople' || orgId === 'trainingtube')
              ? [
                  {
                    id: 'operational-tasks-link',
                    name: translate('operational-tasks'),
                    linkTo: '/admin/operationalTasks',
                    visible: listTracks,
                    icon: 'check_box',
                    isSelected: currentPath === '/admin/operationalTasks',
                  },
                ]
              : []),
            {
              id: 'admin-tags-link',
              name: translate('tags'),
              linkTo: '/admin/tags',
              visible: listTags,
              icon: 'local_offer',
              isSelected: currentPath === '/admin/tags',
            },
            ...(!isLPUser
              ? []
              : [
                  {
                    id: 'admin-users-usage-link',
                    name: translate('users-usage'),
                    linkTo: '/admin/users/usage',
                    visible: listUsers,
                    icon: 'data_usage',
                    isSelected: currentPath === '/admin/users/usage',
                  },
                ]),
          ]
            .filter(item => item.visible)
            .map(item => (
              <span key={item.id}>
                {' '}
                {MenuItemWithIcon(item.name, item.icon, item.linkTo, item.id, item.isSelected, classes, tubeColor)}
              </span>
            ))}
        </div>
      )}

      {isRoleAdmin && (listVideos || listUsers || listChannels || listTracks || listTags) && !originalOrgId && (
        <div className={classes.groupSeparator}>
          <ListSubheader
            disableSticky={true}
            className={classNames(classes.heading, classes.headingPadding)}
            component="div"
          >
            {translate('reports')}
          </ListSubheader>
          {[
            {
              id: 'admin-reports-link',
              name: translate('dashboard'),
              linkTo: '/admin/reports',
              visible: listBranding,
              icon: 'insert_chart',
              isSelected: currentPath === '/admin/reports',
            },
            {
              id: 'admin-shared-reports-link',
              name: translate('share-reports'),
              linkTo: '/admin/reports/shared',
              visible: listBranding,
              icon: 'table_chart',
              isSelected: currentPath === '/admin/reports/shared',
            },
          ]
            .filter(item => item.visible)
            .map(item => (
              <span key={item.id}>
                {MenuItemWithIcon(item.name, item.icon, item.linkTo, item.id, item.isSelected, classes, tubeColor)}
              </span>
            ))}
        </div>
      )}

      {(listBranding || listAzureAd || listClassification) && !originalOrgId && (
        <div className={classNames(classes.mobileHidden, classes.groupSeparator)}>
          <ListSubheader
            disableSticky={true}
            className={classNames(classes.heading, classes.headingPadding)}
            component="div"
          >
            {translate('configuration')}
          </ListSubheader>
          {[
            {
              id: 'admin-branding-link',
              name: translate('branding'),
              linkTo: '/admin/branding',
              visible: listBranding,
            },
            {
              id: 'azure-ad-config-link',
              name: translate('azure-ad-config'),
              linkTo: '/admin/azure-ad',
              visible: listAzureAd,
            },
            {
              id: 'information-classification-link',
              name: translate('information-classification'),
              linkTo: '/admin/information-classification',
              visible: listClassification,
            },
            {
              id: 'security-link',
              name: translate('security'),
              linkTo: '/admin/security',
              visible: listBranding,
            },
            ...(!isLPUser
              ? []
              : [
                  {
                    id: 'admin-organization-link',
                    name: translate('organization'),
                    linkTo: '/admin/organization',
                    visible: listBranding,
                  },
                ]),
          ]
            .filter(item => item.visible)
            .map(item => (
              <Item key={item.name} classes={classes} item={item} translate={translate} isBeta={false} />
            ))}
        </div>
      )}

      {updateOrganization && isLPUser && !originalOrgId && (
        <div
          style={orgId !== 'launchpeople' ? { marginBottom: 10 } : {}}
          className={classNames(classes.mobileHidden, classes.groupSeparator)}
        >
          <ListSubheader
            disableSticky={true}
            className={classNames(classes.heading, classes.headingPadding)}
            component="div"
          >
            {translate('finance')}
          </ListSubheader>
          {[
            {
              id: 'features-services-link',
              name: translate('features-services'),
              linkTo: '/finance/features-services',
              visible: true,
            },
            // {
            //   id: 'internal-users-link',
            //   name: translate('internal-users'),
            //   linkTo: '/finance/internal-users',
            //   visible: true,
            // },
            // {
            //   id: 'external-users-link',
            //   name: translate('external-users'),
            //   linkTo: '/finance/external-users',
            //   visible: true,
            // },
            // {
            //   id: 'video-rendering-link',
            //   name: translate('video-rendering'),
            //   linkTo: '/finance/video-rendering',
            //   visible: videoProcessingEnabled,
            //   isBeta: videoProcessingBeta,
            // },
            // {
            //   id: 'anonymous-plays-link',
            //   name: translate('anonymous-plays'),
            //   linkTo: '/finance/anonymous-plays',
            //   visible: true,
            // },
            {
              id: 'approved-task-ledger-link',
              name: translate('task-ledger'),
              linkTo: '/approved-task-ledger',
              visible: ['launchpeople', 'trainingtube'].includes(orgId),
            },
          ]
            .filter(item => item.visible)
            .map(item => (
              <Item key={item.name} classes={classes} item={item} translate={translate} isBeta={false} />
            ))}
        </div>
      )}

      {isRoleAdmin && (isLPUser || isMasterAdmin) && !originalOrgId && (
        <div
          style={orgId !== 'launchpeople' ? { marginBottom: 50 } : {}}
          className={classNames(classes.mobileHidden, classes.groupSeparator)}
        >
          <ListSubheader
            disableSticky={true}
            className={classNames(classes.heading, classes.headingPadding)}
            component="div"
          >
            {translate('service-consumption')}
          </ListSubheader>
          {[
            {
              id: 'platform-assistance-link',
              name: translate('platform-assistance'),
              linkTo: '/admin/service-consumption/platform-assistance',
              visible: isLPUser && ['trainingtube', 'launchpeople'].includes(orgId),
            },
            ,
            {
              id: 'video-optimization-link',
              name: translate('video-optimization'),
              linkTo: '/admin/service-consumption/video-optimization',
              visible: true,
            },
            {
              id: 'video-optimization-link',
              name: translate('video-translation'),
              linkTo: '/admin/service-consumption/video-translation',
              visible: true,
            },
            {
              id: 'video-optimization-link',
              name: translate('video-processing'),
              linkTo: '/admin/service-consumption/video-processing',
              visible: true,
            },
          ]
            .filter(item => item.visible)
            .map(item => (
              <Item key={item.name} classes={classes} item={item} translate={translate} isBeta={false} />
            ))}
        </div>
      )}

      {updateOrganization && isLPUser && orgId === 'launchpeople' && (
        <div style={{ marginBottom: 50 }} className={classNames(classes.mobileHidden, classes.groupSeparator)}>
          <ListSubheader
            disableSticky={true}
            className={classNames(classes.heading, classes.headingPadding)}
            component="div"
          >
            Trainingtube Network
          </ListSubheader>
          {[
            {
              id: 'orgs',
              name: 'Organizations',
              linkTo: '/orgs',
              visible: true,
            },
            {
              id: 'orgs',
              name: 'Activity',
              linkTo: '/orgs/usage',
              visible: true,
            },
            {
              id: 'usercrm',
              name: 'User CRM',
              linkTo: '/orgs/crm',
              visible: true,
            },
          ]
            .filter(item => item.visible)
            .map(item => (
              <Item key={item.name} classes={classes} item={item} translate={translate} isBeta={false} />
            ))}
        </div>
      )}
    </List>
  )
}

class Sidebar extends React.PureComponent<any, any> {
  componentDidMount() {
    const scrollMenu = document.getElementById('side-scroll-menu')
    if (!!scrollMenu) {
      scrollMenu.scrollTop = this.props.scrollPosition
    }

    // pages
    const isMandatoryPage = window.location.pathname === '/mandatory'
    const isSearchPage = window.location.pathname === '/results'
    const isSubsriptionPage = window.location.pathname === '/subscriptions'
    const isHistoryPage = window.location.pathname === '/history'
    const isLibraryPage = window.location.pathname === '/library'
    const isFavouritePage = window.location.pathname === '/favorites'
    const isSharingPage = window.location.pathname === '/sharings'
    const isMyVideospage = window.location.pathname === '/my-videos'
    const isSupportPage = window.location.pathname === '/support/chat'

    const isAdminPage = includes(window.location.pathname, '/admin')
    const isFinancePage = includes(window.location.pathname, '/finance')

    const isTaskPage = isCurrentPageAdminPage()
    const isNotificationPage = isCurrentPageNotificationPage()
    const isUserTaskPage = window.location.pathname === '/tasks'
    const isAdminOperationalTaskPage = window.location.pathname === '/admin/operationalTasks'
    const isAdminTasksPage = window.location.pathname === '/admin/tasks'

    if (
      (this.props.sidebarStatus &&
        !isSearchPage &&
        !isTaskPage &&
        !isNotificationPage &&
        !isSubsriptionPage &&
        !isHistoryPage &&
        !isLibraryPage &&
        !isFavouritePage &&
        !isSharingPage &&
        !isMyVideospage &&
        !isFinancePage &&
        !isMandatoryPage) ||
      (!this.props.sidebarStatus &&
        (isSearchPage ||
          isTaskPage ||
          isNotificationPage ||
          isHistoryPage ||
          isMandatoryPage ||
          isLibraryPage ||
          isFavouritePage ||
          isSharingPage ||
          isMyVideospage ||
          isFinancePage ||
          isSubsriptionPage))
    ) {
      this.props.toggleSidebar(!this.props.sidebarStatus)
    }

    if (isUserTaskPage || isSupportPage || isAdminOperationalTaskPage || isAdminTasksPage || isAdminPage) {
      this.props.toggleSidebar(false)
    }
  }

  openSidebar = () => {
    this.props.toggleSidebar(true)
  }

  setSubscriptionsMenu = (value: boolean) => {
    this.setState({ isSubscriptionsMenuOpen: value })
  }

  closeSidebar = event => {
    event.stopPropagation()
    this.props.toggleSidebar(false)
  }

  render() {
    const {
      tubeId,
      classes,
      listTags,
      isLPUser,
      orgId,
      listUsers,
      translate,
      tubeColor,
      listVideos,
      listTracks,
      followBeta,
      isPersistent,
      listAzureAd,
      currentPath,
      listChannels,
      originalOrgId,
      getTubeQuery,
      listBranding,
      sidebarStatus,
      listTracksQuery,
      listChannelsQuery,
      followingFeature,
      areTracksExpanded,
      updateOrganization,
      listClassification,
      videoProcessingBeta,
      expandSubscriptions,
      isTasksFeatureEnabled,
      isChatFeatureEnabled,
      isNewChannelLayoutEnabled,
      videoProcessingEnabled,
      getUserFollowDataQuery,
      toggleTracksExpandMenu,
      toggleSubscriptionsMenu,
      isNotificationsFeatureEnabled,
      isRoleAdmin,
      organizationName,
      tubeIcon,
      isMasterAdmin,
    } = this.props

    return (
      <Drawer
        PaperProps={{
          style: {
            backgroundColor: tubeColor,
            overflowY: 'hidden',
            overflowX: 'hidden',
            marginTop: isPersistent ? (originalOrgId ? 90 : 60) : 0,
            zIndex: 3,
          },
        }}
        classes={{
          paper: classNames(classes.drawerPaper, !sidebarStatus && classes.drawerPaperClose),
        }}
        className={classes.drawer}
        variant={isPersistent ? 'persistent' : 'temporary'}
        open={sidebarStatus}
        onClose={() => this.props.toggleSidebar(false)}
        transitionDuration={100}
      >
        <aside
          id="side-scroll-menu"
          style={{ backgroundColor: 'white', width: SIDEBAR_WIDTH }}
          className={classes.sidebar}
          onClick={this.openSidebar}
        >
          <Content
            isLPUser={isLPUser}
            tubeId={tubeId}
            classes={classes}
            getTubeQuery={getTubeQuery}
            followingFeature={followingFeature}
            onPageChange={() => {
              const scrollMenu = document.getElementById('side-scroll-menu')

              this.props.updateScrollPosition(scrollMenu.scrollTop)
            }}
            listTags={listTags}
            listUsers={listUsers}
            translate={translate}
            tubeColor={tubeColor}
            isPersistent={isPersistent}
            listVideos={listVideos}
            listTracks={listTracks}
            followBeta={followBeta}
            listAzureAd={listAzureAd}
            currentPath={currentPath}
            originalOrgId={originalOrgId}
            orgId={orgId}
            listChannels={listChannels}
            listBranding={listBranding}
            listTracksQuery={listTracksQuery}
            listChannelsQuery={listChannelsQuery}
            onCloseSidebar={this.closeSidebar}
            isTasksFeatureEnabled={isTasksFeatureEnabled}
            setTracksExpandMenu={toggleTracksExpandMenu}
            listClassification={listClassification}
            updateOrganization={updateOrganization}
            videoProcessingBeta={videoProcessingBeta}
            isSubscriptionsMenuOpen={expandSubscriptions}
            isTracksMenuExpanded={areTracksExpanded}
            setSubscriptionsMenu={toggleSubscriptionsMenu}
            getUserFollowDataQuery={getUserFollowDataQuery}
            videoProcessingEnabled={videoProcessingEnabled}
            isChatFeatureEnabled={isChatFeatureEnabled}
            isNewChannelLayoutEnabled={isNewChannelLayoutEnabled}
            isNotificationsFeatureEnabled={isNotificationsFeatureEnabled}
            isRoleAdmin={isRoleAdmin}
            isMasterAdmin={isMasterAdmin}
            organizationName={organizationName}
            tubeIcon={tubeIcon}
          />
        </aside>
      </Drawer>
    )
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      toggleSidebar,
      toggleSubscriptionsMenu,
      toggleTracksExpandMenu,
      updateScrollPosition,
    },
    dispatch
  )
}

function mapStateToProps(state: ReduxState, ownProps) {
  const followingFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'following')
  const infClassificationFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'infClassification')
  const tagsFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'tags')
  const videoProcessingFeature = state.auth.organization.featureFlags.find(f => f.featureId === 'videoProcessing')
  return {
    ...ownProps,
    sidebarStatus: state.sidebar.status,
    areTracksExpanded: state.sidebar.areTracksExpanded,
    expandSubscriptions: state.sidebar.expandSubscriptions,
    originalOrgId: state.auth.user.originalOrgId,
    scrollPosition: state.sidebar.scrollPosition,
    listUsers: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.updateOrganization),
    updateOrganization: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.updateOrganization),
    listVideos: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.readVideo),
    listChannels: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.updateOrganization),
    listTracks: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.updateOrganization),
    listTags:
      Boolean(state.auth.user.permissions) &&
      Boolean(state.auth.user.permissions.updateOrganization) &&
      tagsFeature &&
      Boolean(tagsFeature.activatedIn.length),
    listBranding: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.updateOrganization),
    listAzureAd: Boolean(state.auth.user.permissions) && Boolean(state.auth.user.permissions.updateAzureAdCertificate),
    listClassification:
      Boolean(state.auth.user.permissions) &&
      Boolean(state.auth.user.permissions.updateInformationClassification) &&
      infClassificationFeature &&
      Boolean(infClassificationFeature.activatedIn.length),
    translate: getTranslation(state.translation.lang),
    orgId: state.auth.organization.id,
    tubeId: state.auth.tube.id,
    followBeta: followingFeature && followingFeature.status === 'beta',
    videoProcessingEnabled: videoProcessingFeature && Boolean(videoProcessingFeature.activatedIn.length),
    videoProcessingBeta: videoProcessingFeature && videoProcessingFeature.status === 'beta',
    tubeColor: state.auth.tube.color,
    isTasksFeatureEnabled: isTasksFeatureEnabled(state, ''),
    isChatFeatureEnabled: isChatFeatureEnabled(state, ''),
    isNewChannelLayoutEnabled: isNewChannelLayoutFeatureEnabled(state),
    isNotificationsFeatureEnabled: isNotificationsFeatureEnabled(state),
    followingFeature: followingFeature,
    isLPUser: isLPUser(state.auth.user.email),
    isRoleAdmin: state?.auth?.user?.role === 'admin',
    isMasterAdmin: state?.auth?.user?.profile?.isMasterAdmin,
    organizationName: state.auth.organization.name,
    tubeIcon: state.auth.tube.icon,
  }
}

const sidebar = (props: any) => {
  // @ts-ignore
  const Comp = withStyles(styles)(
    compose(
      connect(mapStateToProps, mapDispatchToProps),
      listChannelsData('readChannel'),
      listTracksData(),
      getTubeData(),
      getUserFollowData()
    )(Sidebar)
  )

  return <Comp {...props} />
}

export default sidebar
