enum TaskStatus {
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
  NOT_STARTED = 'notStarted',
  OVERDUE = 'overdue',
  PLANNED = 'planned',
  REQUESTED = 'requested',
  REJECTED = 'rejected',
  PROCESSING = 'processing',
  REVIEW = 'review',
  RE_PROCESS = 'reProcess',
  ISSUE = 'issue',
  UNDER_SUPERVISION = 'underSupervision',
  RESOLVED = 'resolved',
  FEEDBACK_PENDING = 'feedbackPending',
}

export default TaskStatus
