import * as React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core'
import { useState } from 'react'
import CreateSupportTask from '../routes/chat/components/CreateSupportTask'
import { compose } from 'underscore'
import { connect } from 'react-redux'
import { styles } from './GetSupportDialog.styles'
import { ReduxState } from 'src/store/reducers'
import { getTranslation, Translate } from 'src/translation'
import history from 'src/history'

type Props = {
  classes: any
  translate: Translate
}
const GetSupportDialog = (props: Props) => {
  const { classes, translate } = props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Button
        id="get-assistance-button"
        className={`ml-4 ${classes.supportButton}`}
        onClick={() => {
          setIsOpen(true)
        }}
      >
        {translate('get-assistance')}
      </Button>
      {isOpen && (
        <Dialog
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          onClose={() => setIsOpen(false)}
          open={isOpen}
        >
          <DialogTitle>{translate('request-assistant')}</DialogTitle>
          <Typography color="textSecondary" className="px-24">
            {translate('request-an-assistant-subtitle')}
          </Typography>
          <DialogContent>
            <CreateSupportTask
              callback={(chatId?: string) => {
                history.push(`/tasks?selectedTaskChatId=${chatId}&&isAssistedSearch=true`)
              }}
              onclose={() => {
                setIsOpen(false)
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

function mapStateToProps(state: ReduxState, ownProps) {
  return {
    ...ownProps,
    tubeColor: state.auth.tube.color,
    translate: getTranslation(state.translation.lang),
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(GetSupportDialog)
