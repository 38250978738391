import { includes } from 'lodash'
import moment from 'moment'

import { VideoDetails } from 'src/gql/getVideoDetails'

export const getInitials = (name: string) =>
  name
    .replace(/[0-9]/g, '')
    .replace(/[&\/\\#,+()$~%.'\-":*?<>{}]/g, '')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(' ')
    .slice(0, 2)
    .map(letter => (letter[0] ? letter[0].toUpperCase() : ''))

export const getFirstTwoLetters = name =>
  name
    .replace(/[0-9]/g, '')
    .replace(/[&\/\\#,+()$~%.'\-":*?<>{}]/g, '')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .slice(0, 2)
    .toUpperCase()

export const getVideoVersionDetails = (videoDetails: VideoDetails) =>
  Object.keys(videoDetails.perVersion).reduce((r, v) => {
    const tempV = {
      ...videoDetails.perVersion[v].perLanguage[videoDetails.perVersion[v].originalLanguage],
      id: `${videoDetails.perVersion[v].originalLanguage}-${v}`,
      lang: videoDetails.perVersion[v].originalLanguage,
      format: videoDetails.perVersion[v].format,
      isOriginal: true,
      version: v,
      status: videoDetails.perVersion[v].status,
      items: Object.keys(videoDetails.perVersion[v].perLanguage)
        .filter(
          key =>
            key !== videoDetails.perVersion[v].originalLanguage &&
            videoDetails.perVersion[v].langList.find(l => l.id === key)
        )
        .map(key => ({
          ...videoDetails.perVersion[v].perLanguage[key],
          format: videoDetails.perVersion[v].format,
          id: `${key}-${v}`,
          parent: `${videoDetails.perVersion[v].originalLanguage}-${v}`,
          version: v,
          lang: key,
        })),
    }

    return [...r, tempV]
  }, [])

export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity / 100})`
}

/**
 * Get the copy of object without attributes.
 *
 * @param {any} obj
 * @param {any[]} attrsToExclude
 * @returns {T}
 */
export function withoutAttrs<T>(obj: any, attrsToExclude: any[]): T {
  if (Array.isArray(obj)) {
    throw new TypeError('withoutAttrs() expects first argument to be a plain object, array given.')
  }

  const result: any = {}

  Object.keys(obj).forEach((key: string) => {
    if (!includes(attrsToExclude, key)) {
      result[key] = obj[key]
    }
  })

  return result
}

/**
 * Returns the string by removing the skip translations characters [ ] from the string
 *
 * @param {string} data
 * @returns {string}
 */
export const replaceTranslationSkipCharacters = (data: string) => {
  return typeof data === 'string' ? (data || '').replace(/\[|]/g, '') : data
}

/**
 * Returns the tag which are added as break in polly. eg. <break time="5s"/> is converted to empty string.
 *
 * @param {string} data
 * @returns {string}
 */
export const replaceBreaks = (data: string) => {
  return typeof data === 'string' ? (data || '').replace(/<break(.*?)>/g, '') : data
}

/**
 * Checks if the email belongs to a LP user. Lp users are the ones with `@launchpeople.com` or `@trainingtube.com` emails.
 *
 * @param {string} email
 * @returns {boolean}
 */
export const isLPUser = (email: string) => {
  return includes(email, '@trainingtube.com') || includes(email, '@launchpeople.com')
}

/**
 * Formats date to the format used in the app.
 *
 * @param {string} date
 * @returns {string}
 */
export const formatDate = (date: string | Date) => {
  return moment(new Date(date)).format('YYYY-MM-DD HH:mm')
}

export const formatDateWithoutTime = (date: string | Date) => {
  return moment(new Date(date)).format('YYYY-MM-DD')
}

/**
 * Build supplied string by interpolating properties inside delimiters('{ }') with the given parameters.
 *
 * @example
 * interpolate('{name} is here.', {name: 'Barbara'})
 *  => 'Barbara is here.'
 *
 * @param {string} str
 * @param {object} params
 * @returns {string}
 */
export function interpolate(str: string, params: object | null): string {
  let formattedString = str

  if (!params || !Object.keys(params)) {
    return str
  }

  for (const [key, value] of Object.entries(params)) {
    if (value === null || value === undefined) {
      continue
    }

    formattedString = formattedString.replace(new RegExp('{' + key + '}', 'gi'), value.toString())
  }

  return formattedString
}

export const checkIfDevelopmentStage = () => {
  return (
    window.location.href.indexOf('localhost') !== -1 ||
    window.location.href.indexOf(':3000') !== -1 ||
    window.location.href.indexOf('tt-react-qa') !== -1 ||
    window.location.href.indexOf('tt-react-dev') !== -1 ||
    window.location.href.indexOf('tt-react-test') !== -1 ||
    window.location.href.indexOf('.cloudfront.net') !== -1 ||
    window.location.href.indexOf('.dev.trainingtube.com') !== -1 ||
    window.location.href.indexOf('https://trainingtube.trainingtube.com') !== -1
  )
}

export const isCurrentPageAdminPage = () => {
  return window.location.pathname.includes('/support/chat') && !window.location.pathname.includes('/channel')
}

export const isCurrentPageNotificationPage = () => {
  return window.location.pathname.includes('/notifications')
}

export const handleSidebarChange = (tableData, filters) => {
  const data = tableData
    .filter(task => {
      return filters.status.includes(task.status)
    })
    .filter(item => {
      return filters.category.includes(item.category)
    })
    .filter(item => {
      return item.taskSubType && item.taskSubType !== 'N/A' ? filters.taskSubType.includes(item.taskSubType) : true
    })
    .filter(item => {
      return filters.type.includes(item.taskType)
    })

  return data
}

export function compose(...funcs: Function[]) {
  const functions = funcs.reverse()
  return function(...args: any[]) {
    const [firstFunction, ...restFunctions] = functions
    let result = firstFunction.apply(null, args)
    restFunctions.forEach(fnc => {
      result = fnc.call(null, result)
    })
    return result
  }
}

export const getRickTextFromComment = (messageContent: string) => {
  try {
    const parsedJSON = JSON.parse(messageContent)

    if (parsedJSON.type !== 'doc') {
      // the parsed json is not a valid Tiptap valid JSON format
      throw new Error(messageContent)
    }

    if (typeof parsedJSON === 'number') {
      return {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: messageContent,
              },
            ],
          },
        ],
      }
    }

    return parsedJSON
  } catch (error) {
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: messageContent,
            },
          ],
        },
      ],
    }
  }
}
