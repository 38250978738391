import TaskTypes from 'src/enums/TaskTypes'
import TaskSubTypes, { OperationalSubTaskTypes } from 'src/enums/TaskSubTypes'
import { trackStatusToIntlMap, taskTypeToIntlMap, taskCategoryToIntlMap } from 'src/maps/tasks'
import TaskStatus from 'src/enums/TasksStatus'

export const initialFilterTasks = {
  category: [taskCategoryToIntlMap.mandatory, taskCategoryToIntlMap.recommended, taskCategoryToIntlMap.shared],
  type: [
    taskTypeToIntlMap[TaskTypes.WATCH_VIDEO],
    taskTypeToIntlMap[TaskTypes.OPERATIONAL],
    taskTypeToIntlMap[TaskTypes.GOVERNANCE],
  ],
  status: [
    trackStatusToIntlMap.notStarted,
    trackStatusToIntlMap.inProgress,
    trackStatusToIntlMap.completed,
    trackStatusToIntlMap.planned,
    trackStatusToIntlMap.overdue,
    trackStatusToIntlMap.canceled,
    trackStatusToIntlMap.skipped,
    trackStatusToIntlMap.requested,
    trackStatusToIntlMap.issue,
    trackStatusToIntlMap.underSupervision,
    trackStatusToIntlMap.resolved,
    trackStatusToIntlMap.feedbackPending,
  ],
  taskSubType: Object.values(TaskSubTypes),
  search: '',
}

export const approvedTaskLedgerInitialFilters = {
  contentProcessorEmail: ['jpk@trainingtube.com'],
  taskSubType: [
    OperationalSubTaskTypes.CREATE_POWER_POINT_FOR_GENERATE_VIDEO_ORDER,
    OperationalSubTaskTypes.GENERATE_VIDEO_FOR_ORDER,
    OperationalSubTaskTypes.GENERATE_CP07_VIDEO_FOR_ORDER,
    OperationalSubTaskTypes.GENERATE_CP99_VIDEO_FOR_ORDER,
    OperationalSubTaskTypes.GENERATE_CP04_VIDEO_FOR_ORDER,
    OperationalSubTaskTypes.GENERATE_CP02_VIDEO_FOR_ORDER,
    OperationalSubTaskTypes.GENERATE_CP08_VIDEO_FOR_ORDER,
    OperationalSubTaskTypes.TRANSLATE_VIDEO,
  ],
}

export const serviceConsumptionInitialFilters = {
  orgId: [],
  language: [],
  status: [],
}

export const defaultCheckedTasks = {
  category: [taskCategoryToIntlMap.mandatory, taskCategoryToIntlMap.recommended, taskCategoryToIntlMap.shared],
  type: [
    taskTypeToIntlMap[TaskTypes.WATCH_VIDEO],
    taskTypeToIntlMap[TaskTypes.OPERATIONAL],
    taskTypeToIntlMap[TaskTypes.GOVERNANCE],
  ],
  status: [
    trackStatusToIntlMap.notStarted,
    trackStatusToIntlMap.planned,
    trackStatusToIntlMap.inProgress,
    trackStatusToIntlMap.overdue,
  ],
  taskSubType: Object.values(TaskSubTypes),
  search: '',
}

export const editorList = [
  { url: 'effects', anchorText: 'add-chapters-effects', icon: 'exposure' },
  { url: 'manuscript', anchorText: 'add-manuscript-transcript', icon: 'toc' },
  { url: 'quick-guide', anchorText: 'quick-guide', icon: 'batch_prediction' },
  { url: 'links', anchorText: 'add-links', icon: 'link' },
  { url: 'voice-over', anchorText: 'voice-over', icon: 'mic_none' },
  { url: 'upload-files', anchorText: 'upload-files', icon: 'cloud_upload' },
]

export const advancedEditor = [
  { url: '/edit/video/overview/', anchorText: 'overview', icon: 'edit' },
  { url: '/edit/video/tags/', anchorText: 'tags', icon: 'local_offer' },
  { url: '/edit/video/embed-codes/', anchorText: 'embed-codes', icon: 'screen_share' },
]

export const translatorList = [
  { url: 'title', anchorText: 'translate-title-description', icon: 'title' },
  { url: 'effects', anchorText: 'effects', icon: 'exposure' },
  { url: 'manuscript', anchorText: 'manuscript-subtitles', icon: 'toc' },
  { url: 'quick-guide', anchorText: 'quick-guide', icon: 'batch_prediction' },
  { url: 'links', anchorText: 'add-links', icon: 'link' },
  { url: 'voice-annotation', anchorText: 'voice-over', icon: 'mic_none' },
  { url: 'upload-files', anchorText: 'upload-files', icon: 'cloud_upload' },
]

export const MINIMUM_CHAPTER_LENGTH = 200

// For this list we only show the list of languages that are supported by polly
export const TRAININGTUBE_SUPPORTED_LANGUAGES = [
  'en-us',
  'da',
  'sv',
  'no',
  'fi',
  'de',
  'zh',
  'ar',
  'nl',
  'fr-fr',
  'it',
  'hi',
  'ja',
  'ko',
  'pl',
  'pt-pt',
  'ru',
  'ro',
  'es-es',
  'es-mx',
  'tr',
  'is',
  'cy',
  'fr-ca',
  'en-in',
  'en-gb',
  'en-cy',
]

// This is the maximum depth of the playlist tree
export const MAXIMUM_TRACK_DEPTH = 7

export const TOOLBAR_BREAKPOINTS = {
  SMALL: 1040,
  MEDIUM: 1110,
  LARGE: 1235,
}

export const DEFAULT_CHAT_FILTERS = [TaskStatus.IN_PROGRESS, TaskStatus.NOT_STARTED, TaskStatus.OVERDUE]

export const USER_PAGE_TASK_SUB_TYPES = [
  OperationalSubTaskTypes.ASSISTED_SEARCH,
  OperationalSubTaskTypes.SUPPORT_TICKET,
  OperationalSubTaskTypes.ADD_PLAYLIST_TO_CHANNEL,
  OperationalSubTaskTypes.ADD_VIDEO_PAGE,
  OperationalSubTaskTypes.START_VIDEO_OPTIMIZATION,
  OperationalSubTaskTypes.COMPLETE_VIDEO_OPTIMIZATION,
  OperationalSubTaskTypes.UPLOAD_VIDEO,
  OperationalSubTaskTypes.REVIEW_OPTIMIZED_VIDEO,
  OperationalSubTaskTypes.REVIEW_GENERATED_VIDEO,
  OperationalSubTaskTypes.REVIEW_CP07_VIDEO,
  OperationalSubTaskTypes.REVIEW_CP99_VIDEO,
  OperationalSubTaskTypes.REVIEW_CP04_VIDEO,
  OperationalSubTaskTypes.REVIEW_CP02_VIDEO,
  OperationalSubTaskTypes.REVIEW_CP08_VIDEO,
  OperationalSubTaskTypes.REVIEW_CP10_VIDEO,
  OperationalSubTaskTypes.REVIEW_VIDEO_TRANSLATION,
  OperationalSubTaskTypes.PUBLISH_ORIGINAL_VIDEO,
  OperationalSubTaskTypes.REPLY_COMMENT_ON_VIDEO,
  OperationalSubTaskTypes.WATCH_SHARED_VIDEO,
]

export const NON_ADMIN_LP_USER_PAGE_TASK_SUB_TYPES = [
  OperationalSubTaskTypes.ASSISTED_SEARCH,
  OperationalSubTaskTypes.SUPPORT_TICKET,
  OperationalSubTaskTypes.ADD_PLAYLIST_TO_CHANNEL,
  OperationalSubTaskTypes.ADD_VIDEO_PAGE,
  OperationalSubTaskTypes.UPLOAD_VIDEO,
  OperationalSubTaskTypes.START_VIDEO_OPTIMIZATION,
  OperationalSubTaskTypes.COMPLETE_VIDEO_OPTIMIZATION,
  OperationalSubTaskTypes.CREATE_POWER_POINT_FOR_GENERATE_VIDEO_ORDER,
  OperationalSubTaskTypes.APPROVE_POWER_POINT_FOR_GENERATE_VIDEO_ORDER,
  OperationalSubTaskTypes.GENERATE_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.APPROVE_GENERATED_VIDEO,
  OperationalSubTaskTypes.GENERATE_CP07_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.APPROVE_CP07_VIDEO,
  OperationalSubTaskTypes.ADD_CHAPTERS_AND_EFFECTS_ON_VIDEO,
  OperationalSubTaskTypes.APPROVE_CHAPTERS_AND_EFFECTS_ON_VIDEO,
  OperationalSubTaskTypes.PLACE_EFFECTS_AND_TIMING_ON_VIDEO,
  OperationalSubTaskTypes.APPROVE_EFFECTS_AND_TIMING_ON_VIDEO,
  OperationalSubTaskTypes.GENERATE_MANUSCRIPT,
  OperationalSubTaskTypes.APPROVE_MANUSCRIPT,
  OperationalSubTaskTypes.REVIEW_AND_APPROVE_VIDEO_PRODUCTION_ORDER,
  OperationalSubTaskTypes.GENERATE_CP99_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.APPROVE_CP99_VIDEO,
  OperationalSubTaskTypes.GENERATE_CP04_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.APPROVE_CP04_VIDEO,
  OperationalSubTaskTypes.GENERATE_CP02_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.APPROVE_CP02_VIDEO,
  OperationalSubTaskTypes.GENERATE_CP08_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.APPROVE_CP08_VIDEO,
  OperationalSubTaskTypes.TRANSLATE_VIDEO,
  OperationalSubTaskTypes.APPROVE_VIDEO_TRANSLATION,
  OperationalSubTaskTypes.REPLY_COMMENT_ON_VIDEO,
  OperationalSubTaskTypes.WATCH_SHARED_VIDEO,
  OperationalSubTaskTypes.ADD_CHAPTERS_AND_EFFECTS_ON_CP10_VIDEO,
  OperationalSubTaskTypes.APPROVE_CHAPTERS_AND_EFFECTS_ON_CP10_VIDEO,
  OperationalSubTaskTypes.PLACE_EFFECTS_AND_TIMING_ON_CP10_VIDEO,
  OperationalSubTaskTypes.APPROVE_EFFECTS_AND_TIMING_ON_CP10_VIDEO,
  OperationalSubTaskTypes.GENERATE_MANUSCRIPT_CP10_VIDEO,
  OperationalSubTaskTypes.APPROVE_MANUSCRIPT_CP10_VIDEO,
]

export const USER_PAGE_TASK_STATUS = [
  trackStatusToIntlMap.notStarted,
  trackStatusToIntlMap.inProgress,
  trackStatusToIntlMap.completed,
  trackStatusToIntlMap.planned,
  trackStatusToIntlMap.overdue,
  trackStatusToIntlMap.canceled,
  trackStatusToIntlMap.skipped,
  trackStatusToIntlMap.requested,
  trackStatusToIntlMap.rejected,
  trackStatusToIntlMap.processing,
  trackStatusToIntlMap.review,
  trackStatusToIntlMap.reProcess,
  trackStatusToIntlMap.issue,
  trackStatusToIntlMap.underSupervision,
  trackStatusToIntlMap.resolved,
  trackStatusToIntlMap.feedbackPending,
]

export const USER_PAGE_TASK_CATEGORIES = [
  taskCategoryToIntlMap.mandatory,
  taskCategoryToIntlMap.recommended,
  taskCategoryToIntlMap.shared,
]
export const USER_PAGE_TASK_TYPES = [
  taskTypeToIntlMap[TaskTypes.WATCH_VIDEO],
  taskTypeToIntlMap[TaskTypes.OPERATIONAL],
  taskTypeToIntlMap[TaskTypes.GOVERNANCE],
]

export const CONTENT_PROCESSOR_WORK_ENTRY_TASK_SUB_TYPES = [
  OperationalSubTaskTypes.CREATE_POWER_POINT_FOR_GENERATE_VIDEO_ORDER,
  OperationalSubTaskTypes.GENERATE_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.GENERATE_CP07_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.GENERATE_CP99_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.GENERATE_CP04_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.GENERATE_CP02_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.GENERATE_CP08_VIDEO_FOR_ORDER,
  OperationalSubTaskTypes.TRANSLATE_VIDEO,
]

export const TASK_SUB_TYPES = Object.values(TaskSubTypes)

export const NEW_MESSAGE_THRESHOLD_IN_MINUTES = 4

export const VIDEO_CONTAINER_MAX_WIDTH = 70
export const VIDEO_CONTAINER_MIN_WIDTH = 50

export const PROD_CONTENT_PROCESSOR_AUTHORIZED_EMAILS = [
  'wih@trainingtube.com',
  'jpk@trainingtube.com',
  'jpk1@trainingtube.com',
  'krb@trainingtube.com',
  'saa@launchpeople.com',
  'nlz@trainingtube.com',
  'bip@trainingtube.com',
  'heh@trainingtube.com',
  'john_doe@trainingtube.com',
  'lah@trainingtube.com',
  'san_cop@trainingtube.com',
  'rah@trainingtube.com',
  'fis@trainingtube.com',
  'nag@trainingtube.com',
  'fif@trainingtube.com',
  'kit@trainingtube.com',
  'sat@trainingtube.com',
  'maa@trainingtube.com',
  'mak@trainingtube.com',
]

export const DEV_CONTENT_PROCESSOR_AUTHORIZED_EMAILS = [
  'jpk@trainingtube.com',
  'saa@launchpeople.com',
  'bip@trainingtube.com',
  'bon@trainingtube.com',
  'contributor@trainingtube.com',
  'bip_dev_test_01@trainingtube.com',
]

export const VIDEO_SERVICE_BALANCE_COMMAND_AUTHORIZED_EMAILS = ['jpk@trainingtube.com', 'heh@trainingtube.com']
