import gql from 'graphql-tag'
import TaskStatus from 'src/enums/TasksStatus'

export type TaskCommand =
  | {
      type: 'addComment'
      payload: {
        taskId: string
        comment: string
      }
    }
  | { type: 'editComment'; payload: { commentId: string; comment: string } }
  | {
      type: 'deleteComment'
      payload: {
        commentId: string
      }
    }
  | { type: 'emailChatAboutComment'; payload: { commentId: string } }
  | {
      type: 'changeTaskChannel'
      payload: {
        taskId: string
        channelId: string
      }
    }
  | {
      type: 'resolveSupportTicketTask'
      payload: {
        taskId: string
        status: TaskStatus.RESOLVED | TaskStatus.FEEDBACK_PENDING
      }
    }
  | {
      type: 'resubmitSupportTicketTask'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'resolveAssistedSearchTask'
      payload: {
        taskId: string
        status: TaskStatus.RESOLVED | TaskStatus.FEEDBACK_PENDING
      }
    }
  | {
      type: 'resubmitAssistedSearchTask'
      payload: {
        taskId: string
      }
    }

export const taskCommandVariables = (command: TaskCommand) => {
  return {
    variables: {
      input: {
        type: command.type,
        payload: command.payload,
      },
    },
  }
}

export const TASK_COMMAND_MUTATION = gql`
  mutation taskCommentCommand($input: REST!) {
    res(input: $input) @rest(type: "TaskComment", path: "/taskCommand", method: "POST", endpoint: "command") {
      id
    }
  }
`

export const COMPLETE_TASK = gql`
  mutation completeTask($input: REST!) {
    res(input: $input) @rest(type: org, path: "/organizationCommand", method: "POST", endpoint: "command") {
      id
    }
  }
`

export const CREATE_SUPPORT_TASK = gql`
  mutation createSupportTask($input: REST!) {
    res(input: $input) @rest(type: "createSupportTask", path: "/userCommand", method: "POST", endpoint: "command") {
      taskId
    }
  }
`
