import gql from 'graphql-tag'
import { omit } from 'underscore'

export type UserCommand =
  | {
      type: 'updatePreferredLanguage'
      payload: {
        language: string
      }
    }
  | {
      type: 'followVideo' | 'unfollowVideo'
      payload: {
        videoId: string
      }
    }
  | {
      type: 'followTrack' | 'unfollowTrack'
      payload: {
        trackId: string
      }
    }
  | {
      type: 'followChannel' | 'unfollowChannel'
      payload: {
        channelId: string
      }
    }
  | {
      type: 'forceFollowChannel' | 'forceUnfollowChannel'
      payload: {
        channelId: string
        userOrgId: string
        userEmail: string
        message?: string
        sendEmailNotification?: string
      }
    }
  | {
      type: 'updateUserChannelAlertStatus'
      payload: {
        channelId: string
        userOrgId?: string
        userEmail: string
        isChannelAlertEnabled: boolean
      }
    }
  | {
      type: 'updateNotificationIsSeenStatus'
      payload: {
        isSeen: boolean
        notificationIds: string[]
      }
    }
  | {
      type: 'completeUserConfigurationStatus'
      payload: {
        isConfigurationCompleted: boolean
      }
    }
  | {
      type: 'updateNotificationIsOpenedStatus'
      payload: {
        isOpened: boolean
        notificationId: string
      }
    }
  | {
      type: 'addUserTag' | 'removeUserTag'
      payload: {
        email: string
        tagGroup: string
        tagValue: string
      }
    }
  | {
      type: 'updateTooltipPopupPreference'
      payload: {
        disabled: boolean
      }
    }
  | {
      type: 'updateUserProfile'
      payload: {
        lastName?: string
        firstName?: string
        phoneNumber?: string
        jobArea?: string
        jobTitle?: string
        browserLanguage?: string
        profilePic?: string
        country?: string
      }
    }
  | {
      type: 'updateCollaborationTaskAssignee'
      payload: {
        taskId: string
        userId?: string
        userEmail?: string
      }
    }
  | {
      type: 'resetCollaborationTaskAssignee'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'rejectVerifyDomainUserTask'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'approveOrganization'
      payload: {
        taskId: string
        sendEmailNotification: boolean
      }
    }
  | {
      type: 'approveVideoProcessingRequest'
      payload: {
        taskId: string
        deliveryDate: string
      }
    }
  | {
      type: 'completeVideoProduction'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'approveVideoProduction'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'reviewVideoProduction'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'shareVideo'
      payload: {
        videoId: string
        userEmails: string[]
        sendEmailNotification: boolean
      }
    }
  | {
      type: 'createAssistedSearchTask'
      payload: {
        taskTitle: string
      }
    }
  | {
      type: 'rejectVideoProduction'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'reSubmitVideoProduction'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'reviewVideoGeneration'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'completeSupportTask'
      payload: {
        taskId: string
      }
    }

export const userCommandGQL = gql`
  mutation userCommand($input: REST!) {
    res(input: $input) @rest(type: "user", path: "/userCommand", method: "POST", endpoint: "command") {
      id
      taskId
    }
  }
`

export const userCommandMutation = () => ({
  mutation: userCommandGQL,
})

export const userCommandVariables = (command: UserCommand) => ({
  variables: {
    input: {
      type: command.type,
      payload: omit(command.payload, val => val === null || val === undefined),
    },
  },
})
